import React from 'react';
import Work from './Section';
import About from './About';

export default function Main() {
  return (
    <div>
        <About> </About>
        <Work></Work>
    </div>
  )
}
